import { useState } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import localeData from 'dayjs/plugin/localeData'
import isoWeek from 'dayjs/plugin/isoWeek'
import 'react-datepicker/dist/react-datepicker.css'

import Month from '../Month'

import '../../../css/calendar.css'

import IconLogout from '../../../images/icon-logout.svg'

import fetchIssues, { IIssue } from '../../../utils/fetchIssues'
import { useEffect } from 'react'
import fetchMembers, { IMember } from '../../../utils/fetchMembers'
import DatePickerCustomInput from './DatePickerCustomInput'
import MemberSelect from './MemberSelect'
import ProjectSelect from './ProjectSelect'
import { IProject } from '../../../utils/fetchProjects'

dayjs.extend(isoWeek)
dayjs.extend(updateLocale)
dayjs.extend(localeData)
dayjs.updateLocale('en', {
  months: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  monthsShort: [
    'Янв',
    'Фев',
    'Мар',
    'Апр',
    'Май',
    'Июн',
    'Июл',
    'Авг',
    'Сен',
    'Окт',
    'Нов',
    'Дек',
  ],
  weekdays: [
    'Воскресенье',
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
  ],
  weekdaysShort: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
  weekdaysMin: ['Вс', 'Пн', 'Вт', 'Чт', 'Ср', 'Пт', 'Сб'],
})

export default function Calendar(): JSX.Element {
  const [date] = useState<dayjs.Dayjs>(dayjs())
  const [month, setMonth] = useState<Date>(
    new Date(dayjs().startOf('month').format('YYYY-MM'))
  )
  const [projectMembers, setProjectMembers] = useState<IMember[]>([])
  const [selectedMembers, setSelectedMembers] = useState<string[]>([''])
  const [issues, setIssues] = useState<IIssue[]>([])
  const [project, setProject] = useState<IProject>({
    fullPath: process.env.REACT_APP_DEFAULT_PROJECT_PATH,
    id: process.env.REACT_APP_DEFAULT_PROJECT_ID,
  })

  useEffect(() => {
    if (!project.fullPath) return

    fetchIssues(project.fullPath).then((issues: IIssue[]) => setIssues(issues))
    fetchMembers(project.fullPath).then((members: IMember[]) =>
      setProjectMembers(members)
    )
  }, [project.fullPath])

  return (
    <div className='calendar-wrapper'>
      <div className='page-header'>
        <div>
          <DatePickerCustomInput
            selected={month}
            setMonth={(date: Date) => setMonth(date)}
          />
        </div>
        <MemberSelect
          selectedMembers={selectedMembers}
          members={projectMembers}
          setSelectedMembers={setSelectedMembers}
        />
        <ProjectSelect
          project={project}
          setProject={setProject}
          setSelectedMembers={setSelectedMembers}
        />
        <Link to='/exit' className='calendar__exit-btn'>
          <img
            className='calendar__exit-btn-image'
            src={IconLogout}
            alt='Logout'
          />
        </Link>
      </div>

      <div className='calendar'>
        <div className='calendar-header'>{CalendarHeader()}</div>
        <div className='calendar-body'>
          <Month
            issues={issues}
            date={date}
            month={month}
            members={selectedMembers}
            projectId={project.id}
          />
        </div>
      </div>
    </div>
  )
}

function CalendarHeader(): JSX.Element[] {
  let content: JSX.Element[] = []

  for (let i = 1; i <= 8; i++) {
    const headers: dayjs.WeekdayNames = dayjs.weekdaysMin()
    let headerLabel: string = headers[i]
    let className: string = 'header'

    if (i === 6 || i === 7) {
      className += ' header-weekend'
    }

    if (i === 7) {
      headerLabel = headers[0]
    }
    if (i === 8) {
      headerLabel = 'Часы'
    }

    content.push(
      <div key={i + Math.random()} className={className}>
        <span>{headerLabel}</span>
      </div>
    )
  }

  return content
}
