import { useEffect } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'

export default function Exit() {
  let navigate: NavigateFunction = useNavigate()
  const clientId: string = process.env.REACT_APP_OAUTH_ID
  const token: string = sessionStorage.getItem('oauth_access_token')

  useEffect(() => {
    fetch(
      `https://gitlab.com/oauth/revoke?client_id=${clientId}&token=${token}`,
      {
        method: 'POST',
      }
    )
      .then(() => {
        sessionStorage.removeItem('oauth_access_token')
        sessionStorage.removeItem('oauth_refresh_token')
        navigate('/')
      })
      .catch(err => console.log(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}
